import React, {useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'
import BrevoConversationsWidget from '../components/chat'
import FreeProductOffer from '../components/FreeProductOffer'
import Share from '../components/share'
import '../styles/verb.css'

const VerbTemplate = ({data, location}) => {
  const verb = data.verbsJson
  const suggestedVerbs = data.allVerbsJson.edges
  const {href} = location
  const [copiedTense, setCopiedTense] = useState(null)

  const getConjugationValue = (conjugation, pronoun) => {
    const sanitizedPronoun = pronoun
      .replace('/', '_')
      .replace("'", '')
      .toLowerCase()

    if (conjugation.tense.toLowerCase() === 'impératif') {
      return conjugation.conjugation[sanitizedPronoun] || ''
    }

    return conjugation.conjugation[sanitizedPronoun]
  }

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const copyToClipboard = (text, tense) => {
    navigator.clipboard.writeText(text)
    setCopiedTense(tense)
    setTimeout(() => setCopiedTense(null), 2000)
  }

  const filteredConjugations = verb.conjugations.filter(
    conjugation =>
      ![
        'subjonctif',
        'subjonctif présent',
        'subjonctif passé',
        'subjonctif imparfait',
        'subjonctif plus-que-parfait',
      ].includes(conjugation.tense.toLowerCase()),
  )

  const secondTableConjugations = verb.conjugations.filter(conjugation =>
    [
      'subjonctif',
      'subjonctif présent',
      'subjonctif passé',
      'subjonctif imparfait',
      'subjonctif plus-que-parfait',
    ].includes(conjugation.tense.toLowerCase()),
  )

  const renderConjugationListItem = (conjugation, pronoun, label) => {
    const value = getConjugationValue(conjugation, pronoun)
    if (!value) return null
    return (
      <li className="conjugation-item">
        <strong>{label}:</strong>{' '}
        <span className="conjugation-value">{value}</span>
        <button
          className="button is-small is-light copy-button"
          onClick={() => copyToClipboard(value, conjugation.tense)}
          title="Copiar conjugación"
        >
          📋
        </button>
        {copiedTense === conjugation.tense && (
          <span className="copy-feedback">¡Copiado!</span>
        )}
      </li>
    )
  }

  const renderConjugationJe = (conjugation, pronoun, label) => {
    const value =
      getConjugationValue(conjugation, pronoun) ||
      getConjugationValue(conjugation, 'j_')
    if (!value) return null
    return (
      <li className="conjugation-item">
        <strong>{label}:</strong>{' '}
        <span className="conjugation-value">{value}</span>
        <button
          className="button is-small is-light copy-button"
          onClick={() => copyToClipboard(value, conjugation.tense)}
          title="Copiar conjugación"
        >
          📋
        </button>
        {copiedTense === conjugation.tense && (
          <span className="copy-feedback">¡Copiado!</span>
        )}
      </li>
    )
  }

  const renderConjugationQueJe = conjugation => {
    const value =
      getConjugationValue(conjugation, 'que_je') ||
      getConjugationValue(conjugation, 'que_j_')
    if (!value) return null
    return (
      <li className="conjugation-item">
        <strong>Que je/j':</strong>{' '}
        <span className="conjugation-value">{value}</span>
        <button
          className="button is-small is-light copy-button"
          onClick={() => copyToClipboard(value, conjugation.tense)}
          title="Copiar conjugación"
        >
          📋
        </button>
        {copiedTense === conjugation.tense && (
          <span className="copy-feedback">¡Copiado!</span>
        )}
      </li>
    )
  }

  return (
    <Layout>
      <SEO
        title={verb.seo.title}
        description={verb.seo.description}
        hasJsonLd
        article
      />

      <section className="section">
        <div className="container">
          <div className="content-wrapper">
            {/* Back Button */}
            <Link className="top-back-button" to="../">
              <span>🔙</span> Volver
            </Link>

            {/* Header */}
            <div className="header-content">
              <h1 className="title">
                {capitalizeFirstLetter(verb.infinitive)}
              </h1>
            </div>

            {/* Significado */}
            <div className="content-card">
              <header className="card-header has-background-primary">
                <p className="card-header-title has-text-white">Significado</p>
              </header>
              <div className="card-content">
                <div className="content">{verb.meaning}</div>
              </div>
            </div>

            {/* Uso */}
            <div className="content-card">
              <header className="card-header has-background-info">
                <p className="card-header-title has-text-white">Uso</p>
              </header>
              <div className="card-content">
                <div className="content">{verb.usage}</div>
              </div>
            </div>

            {/* Ejemplos */}
            <div className="content-card">
              <header className="card-header has-background-link">
                <p className="card-header-title has-text-white">Ejemplos</p>
              </header>
              <div className="card-content">
                {verb.examples.map((el, index) => (
                  <p key={index} className="example-sentence">
                    {el}
                  </p>
                ))}
              </div>
            </div>

            {/* Tabla de conjugación */}
            <div className="content-card">
              <header className="card-header has-background-warning">
                <p className="card-header-title has-text-black">
                  Tabla de conjugación
                </p>
              </header>
              <div className="card-content">
                <div className="table-container desktop-only">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tiempo</th>
                        <th>Je/J'</th>
                        <th>Tu</th>
                        <th>Il/elle/on</th>
                        <th>Nous</th>
                        <th>Vous</th>
                        <th>Ils/elles</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredConjugations.map(conjugation => (
                        <tr key={conjugation.tense}>
                          <td>{conjugation.tense}</td>
                          <td>
                            {getConjugationValue(conjugation, 'je') ||
                              getConjugationValue(conjugation, 'j_')}
                          </td>
                          <td>{getConjugationValue(conjugation, 'tu')}</td>
                          <td>
                            {getConjugationValue(conjugation, 'il_elle_on')}
                          </td>
                          <td>{getConjugationValue(conjugation, 'nous')}</td>
                          <td>{getConjugationValue(conjugation, 'vous')}</td>
                          <td>
                            {getConjugationValue(conjugation, 'ils_elles')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="table-container desktop-only">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Tiempo</th>
                        <th>Que je/j'</th>
                        <th>Que tu</th>
                        <th>Qu'il/elle/on</th>
                        <th>Que nous</th>
                        <th>Que vous</th>
                        <th>Qu'ils/elles</th>
                      </tr>
                    </thead>
                    <tbody>
                      {secondTableConjugations.map(conjugation => (
                        <tr key={conjugation.tense}>
                          <td>{conjugation.tense}</td>
                          <td>
                            {getConjugationValue(conjugation, 'que_je') ||
                              getConjugationValue(conjugation, 'que_j_')}
                          </td>
                          <td>{getConjugationValue(conjugation, 'que_tu')}</td>
                          <td>
                            {getConjugationValue(conjugation, 'qu_il_elle_on')}
                          </td>
                          <td>
                            {getConjugationValue(conjugation, 'que_nous')}
                          </td>
                          <td>
                            {getConjugationValue(conjugation, 'que_vous')}
                          </td>
                          <td>
                            {getConjugationValue(conjugation, 'qu_ils_elles')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="conjugation-list mobile-only">
                  {verb.conjugations.map(conjugation => (
                    <div
                      key={conjugation.tense}
                      className="conjugation-list-item"
                    >
                      <h3>{conjugation.tense}</h3>
                      <ul>
                        {renderConjugationJe(conjugation, 'je', 'Je/J')}
                        {renderConjugationListItem(conjugation, 'tu', 'Tu')}
                        {renderConjugationListItem(
                          conjugation,
                          'il_elle_on',
                          'Il/elle/on',
                        )}
                        {renderConjugationListItem(conjugation, 'nous', 'Nous')}
                        {renderConjugationListItem(conjugation, 'vous', 'Vous')}
                        {renderConjugationListItem(
                          conjugation,
                          'ils_elles',
                          'Ils/elles',
                        )}
                        {renderConjugationQueJe(conjugation)}
                        {renderConjugationListItem(
                          conjugation,
                          'que_tu',
                          'Que tu',
                        )}
                        {renderConjugationListItem(
                          conjugation,
                          'qu_il_elle_on',
                          "Qu'il/elle/on",
                        )}
                        {renderConjugationListItem(
                          conjugation,
                          'que_nous',
                          'Que nous',
                        )}
                        {renderConjugationListItem(
                          conjugation,
                          'que_vous',
                          'Que vous',
                        )}
                        {renderConjugationListItem(
                          conjugation,
                          'qu_ils_elles',
                          "Qu'ils/elles",
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Share Section */}
            <div className="share-section">
              <Share redirect={href} title={verb.seo.title} />
            </div>

            {/* Suggested Verbs */}
            <div className="content-card suggested-verbs">
              <header className="card-header has-background-primary">
                <p className="card-header-title has-text-white">
                  Otros verbos que te pueden interesar
                </p>
              </header>
              <div className="card-content">
                <div className="suggested-verbs-grid">
                  {suggestedVerbs.map(({node}) => (
                    <Link
                      key={node.slug}
                      to={`/verbos/${node.slug}`}
                      className="suggested-verb-card"
                    >
                      <span className="verb-name">{node.infinitive}</span>
                      <span className="verb-meaning">{node.meaning}</span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    verbsJson(slug: {eq: $slug}) {
      seo {
        title
        description
      }
      infinitive
      conjugations {
        tense
        conjugation {
          je
          j_
          tu
          il_elle_on
          nous
          vous
          ils_elles

          que_je
          que_j_
          que_tu
          qu_il_elle_on
          que_nous
          que_vous
          qu_ils_elles
        }
      }
      meaning
      usage
      examples
    }
    allVerbsJson(
      filter: {slug: {ne: $slug}}
      limit: 6
      sort: {fields: infinitive, order: ASC}
    ) {
      edges {
        node {
          slug
          infinitive
          meaning
        }
      }
    }
    FreeProductImage: file(name: {eq: "FreeProductImageVerb"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default VerbTemplate
